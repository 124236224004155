/* eslint-disable react/no-unstable-nested-components */

import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { LinearGradient } from 'expo-linear-gradient';
import React, { createRef, useCallback, useEffect, useRef, useState } from 'react';
import {
  LayoutChangeEvent,
  NativeScrollEvent,
  NativeSyntheticEvent,
  Pressable,
  ScrollView,
  View,
} from 'react-native';
import Animated, { useAnimatedStyle, useSharedValue, withSpring } from 'react-native-reanimated';
import { CommonActions, useFocusEffect, useNavigation } from '@react-navigation/core';
import Button from '@atoms/Button';
import CustomText from '@atoms/CustomText';
import { FooterButton } from '@atoms/FooterButton';
import Skeleton from '@atoms/Skeleton';
import { AnalyticsEvents } from '@constants/analyticsEvents';
import { HEADER_HEIGHT, isDesktop, isWebResponsive, SCREEN_WIDTH } from '@constants/platform';
import { useTranslations } from '@hooks/useTranslation';
import { CategoryData } from '@interfaces/explore';
import Controls from '@molecules/Controls';
import GoBackButton from '@molecules/GoBackButton';
import { Layout } from '@organisms/Layout';
import Slider from '@organisms/Slider';
import { useGameDetail } from '@services/useGameDetail';
import { authTokenSelector, setSelectedFiltersHandler, setShownAdHandler, useStore } from '@store/index';
import handleAnalyticsEvent from '@utils/analytics';
import { moderateScale } from '@utils/scaling';
import { RootStackNavigator } from 'app/navigation/types';
import { LikeCTAS } from '@molecules/LikeCTAS';
import { mediumVibration } from '@utils/vibration';
import SmallShareIcon from '@assets/svg/SmallShareIcon';
import share, { appURL } from '@utils/shareApp';
import Comments from '@organisms/Comments';
import SliderWeb from '@organisms/SliderWeb';
import { Colors, Spacing } from '@theme';
import { AvatarProfile } from '@atoms/AvatarProfile';
import { getLanguage } from '@utils/getLanguage';
import { useCountryIsoCode } from '@hooks/useCountryIsoCode';
import { BANNER_SIZE } from '@constants/sizes';
import AdImageComponent from '@organisms/AdComponent';
import GameQuestBanner from '@atoms/GameQuestBanner/index.web';
import { getGameSlug } from '@utils/common';
import useFeatureFlag, { gameQuestEventSelector } from '@store/featureFlag';
import useGameDescription from '@hooks/useGameDescription';
import { ControlTypeDesktopEnum, ControlTypeEnum } from 'app/enums/controlTypes.enum';
import { GoogleAdManagerUnitIdEnum } from '@atoms/GoogleAdManagerBanner/constants';
import AdVideo from '@molecules/AdVideo';

import { GameImage } from './GameImage/GameImage';
import { SensitiveMaterial } from './SensitiveBadge/SensitiveBadge';
import { lottie } from './utils';
import { styles } from './styles.web';
import { ShareNetwork } from '@phosphor-icons/react';

type GameBuilderPageProps = NativeStackScreenProps<RootStackNavigator, 'GameBuilder'>;

const GameBuilderPage = ({ route, navigation }: GameBuilderPageProps) => {
  // Refs
  // const mainViewRef = createRef<ScrollView>();

  // Hooks
  // const { getState } = useNavigation();
  const authToken = useStore(authTokenSelector);
  const headerAnimation = useSharedValue(0);
  const headerOpacityStyle = useAnimatedStyle(() => ({ opacity: headerAnimation.value }));
  // const i18n = useTranslations();
  // const countryISOCode = useCountryIsoCode();

  // // States
  // const language = getLanguage();
  // const { routes } = getState();

  const headerBackground = () => <Animated.View style={[headerOpacityStyle, styles.navbarBackground]} />;

  // Effects

  useEffect(() => {
    if (!authToken) {
      navigation.navigate('Login');
    }

    const dateExpirationTokenCookie: string = new Date().setDate(new Date().getDate() + 1).toString();

    document.cookie = `token=${authToken}; expires=${dateExpirationTokenCookie}; domain=.gato.us; path=/; SameSite=None; Secure`;
  }, [authToken]);

  useFocusEffect(() => {
    navigation.setOptions({
      headerTransparent: true,
      headerBackground,
      title: `GameBuilder`,
    });
  });

  // render
  window.addEventListener('message', (e) => {
    const { action, data } = e.data;

    if (action === 'navigate-login') {
      navigation.navigate('Login');
    }

    if (action === 'navigate-gamepage') {
      navigation.navigate('GamePage', { game: data.id, name: data.slug });
    }

    if (action === 'play-game') {
      navigation.navigate('GamePage', { game: data.id, name: data.slug, autoStartGame: true });
    }
  });

  const url = 'https://gamebuilder.gato.us/index.html';

  return (
    <View style={{ position: 'relative', flex: 1 }}>
      <iframe title="gato-game-builder" src={url} style={{ width: '100%', height: '100%', border: 0 }} />
    </View>
  );
};

export default GameBuilderPage;
