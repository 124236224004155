import { create } from 'zustand';

interface Store {
  christmas: boolean;
  toggleChristmasEvent: () => void;
  gameQuestEvent: boolean;
  toggleGameQuestEvent: () => void;
  halloweenEvent: boolean;
  toggleHalloweenEvent: () => void;
}

// TODO: maybe we could make an event enum and setup a calendar with javascript Date API to handle it automatically - Juan
const useFeatureFlag = create<Store>((set) => ({
  christmas: false,
  toggleChristmasEvent(): void {
    set((state) => ({ christmas: !state.christmas }));
  },
  gameQuestEvent: true,
  toggleGameQuestEvent: () => set((state) => ({ gameQuestEvent: !state.gameQuestEvent })),
  halloweenEvent: true,
  toggleHalloweenEvent: () => set((state) => ({ halloweenEvent: !state.halloweenEvent })),
}));

// Selectors
export const christmasEventSelector = (state: Store) => state.christmas;
export const gameQuestEventSelector = (state: Store) => state.gameQuestEvent;
export const halloweenEventSelector = (state: Store) => state.halloweenEvent;

// Handlers
export const toggleChristmasEventHandler = (state: Store) => state.toggleChristmasEvent;
export const toggleGameQuestEventHandler = (state: Store) => state.toggleGameQuestEvent;
export const toggleHalloweenEventHandler = (state: Store) => state.toggleHalloweenEvent;

export default useFeatureFlag;
